import { disableFunctionalities, enableFunctionalities } from '@/api/pmfunctionality';

const disableFunctionality = {
	id: 'disableFunctionality',
	selectionType: 'multiple',
	label: 'pmfunctionality.actions.disableFunctionality',
	functionality: 'UPDATE_PMFUNCTIONALITY',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmfunctionality.actions.disableFunctionality');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmfunctionalityid: registries[i].pmfunctionalityid
			});
		}

		const data = await disableFunctionalities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableFunctionality = {
	id: 'enableFunctionality',
	selectionType: 'multiple',
	label: 'pmfunctionality.actions.enableFunctionality',
	functionality: 'UPDATE_PMFUNCTIONALITY',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmfunctionality.actions.enableFunctionality');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmfunctionalityid: registries[i].pmfunctionalityid
			});
		}

		const data = await enableFunctionalities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableFunctionality, enableFunctionality]
};
